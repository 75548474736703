import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const NotificationBannerStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      backgroundColor: theme?.color?.dark_green,
      title: theme?.typeStyles?.h2,
    };

    validateProps('PageHeaderStyled', { ...props });

    return css`
      background: ${props.backgroundColor};
      text-align: center;
      ${spacer('small', 'padding-top-bottom')};

      .title {
        ${props.title};
        color: #fff;
      }
      .content {
        ${spacer('small')}
        color: #fff;
        a {
          color: #fff;
        }
      }
    `;
  }}
`;

export default NotificationBannerStyled;
