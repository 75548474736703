import React from 'react';
import { Link, withPrefix } from 'gatsby';

import { Container } from '../layout/';

import HeaderStyled from './header.styled';

import { content_page_schema } from '~/schema/site.schema';

const nav = Object.keys(content_page_schema)
  .reduce(
    (
      arr: {
        path: string;
        id: string;
        title: string;
        includeInMenu: boolean;
      }[],
      key: keyof typeof content_page_schema
    ) => {
      return [...arr, content_page_schema[key]];
    },
    []
  )
  .filter((x) => x.includeInMenu);

const Header = () => {
  return (
    <HeaderStyled>
      <Container>
        <Link to="/" className="logo" title={'Go to homepage'}>
          <img
            src={withPrefix('/images/david_j_fry.svg')}
            width={277}
            height={83}
            alt={'David J Fry - Fine Art -'}
          />
        </Link>
        <nav className="nav">
          {nav.map((item) => (
            <div className="nav__item" key={item.title}>
              <Link to={item.path}>{item.title}</Link>
            </div>
          ))}
        </nav>
      </Container>
      <div className="image-strip" />
    </HeaderStyled>
  );
};

export default Header;
