const color = {
  green: '#178724',
  dark_green: '#07270b',
};

const text = {
  base: '#333',
  heading: '#222',
};

const link = {
  base: color.green,
  hover: color.dark_green,
};

export default { ...color, text, link };
