import React from 'react';
import { Helmet } from 'react-helmet';
import { meta_schema } from '~/schema/site.schema';
import { SchemaOrgTypes } from './schemaOrg.types';

const SchemaOrg = (props: {
  schema:
    | SchemaOrgTypes.Schema_Image
    | SchemaOrgTypes.Schema_Painting
    | SchemaOrgTypes.Schema_WebPage
    | SchemaOrgTypes.Schema_WebSite;
}) => {
  const schema: SchemaOrgTypes.Schema_Object =
    props.schema['@type'] === 'Painting'
      ? {
          '@context': 'https://schema.org/',
          '@type': 'Painting',
          name: props.schema.name,
          image: props.schema.image,
          artMedium: props.schema.artMedium,
          artist: {
            '@type': 'Person',
            name: meta_schema.full_name,
          },
          creator: {
            '@type': 'Person',
            name: meta_schema.full_name,
          },
          contentLocation: {
            '@type': 'AdministrativeArea',
            name: props.schema.contentLocation,
          },
          locationCreated: {
            '@type': 'AdministrativeArea',
            name: props.schema.contentLocation,
          },
        }
      : props.schema;

  if (props.schema['@type'] === 'ImageObject')
    schema.author = meta_schema.full_name;

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          ...schema,
        })}
      </script>
    </Helmet>
  );
};

export default SchemaOrg;
