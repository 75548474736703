import { breakpoint, breakpointDef_string, breakpoint_keys } from '../layout';

/**
 * Media query css string generated from breakpoint key
 */
const mq: breakpointDef_string = [
  ...(Object.keys(breakpoint) as breakpoint_keys[]),
].reduce(
  (obj, key) => ({
    ...obj,
    [key]: `only screen and (min-width: ${breakpoint[key]}px)`,
  }),
  {}
);

export default mq;
