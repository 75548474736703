import React from 'react';

import RowStyled from '../component.styled/row.styled';

const Row = (props: { children: any; reverse?: boolean }) => {
  return (
    <RowStyled className="row" reverse={props.reverse}>
      {props.children}
    </RowStyled>
  );
};

export default Row;
