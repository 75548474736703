// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-views-content-index-ts": () => import("./../../../src/views/content/index.ts" /* webpackChunkName: "component---src-views-content-index-ts" */),
  "component---src-views-gallery-index-ts": () => import("./../../../src/views/gallery/index.ts" /* webpackChunkName: "component---src-views-gallery-index-ts" */),
  "component---src-views-home-index-ts": () => import("./../../../src/views/home/index.ts" /* webpackChunkName: "component---src-views-home-index-ts" */),
  "component---src-views-painting-index-ts": () => import("./../../../src/views/painting/index.ts" /* webpackChunkName: "component---src-views-painting-index-ts" */)
}

