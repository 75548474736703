import color from './color';
import layout from './layout';
import typography from './typography';

export default {
  color,
  layout,
  ...typography,
  button: {
    backgroundColor: color.green,
    textColor: '#fff',
  },
  loader: {
    bgColor: 'rgba(0, 0, 0, 0.8)',
    textColor: '#fff',
    color: color.green,
  },
};
