import React from 'react';

import ColumnStyled from '../component.styled/column.styled';

import { breakpointDef_number } from '~/theme/layout';

const Column = (props: { children: any; columns: breakpointDef_number }) => {
  return (
    <ColumnStyled className="column" columns={props.columns}>
      <div className="gutter">{props.children}</div>
    </ColumnStyled>
  );
};

export default Column;
