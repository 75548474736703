/**
 * Type: Defines valid keys to use when referring to media query breakpoints
 */
export type breakpoint_keys =
  | 'base'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'smedium'
  | 'medium'
  | 'mlarge'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

/**
 * Type: Defines valid keys|pair for breakpoints of type number | string
 */
export type breakpointDef_all = { [key in breakpoint_keys]?: number | string };

/**
 * Type: Defines valid keys|pair for breakpoints of type number
 */
export type breakpointDef_number = { [key in breakpoint_keys]?: number };

/**
 * Type: Defines valid keys|pair for breakpoints of type string
 */
export type breakpointDef_string = { [key in breakpoint_keys]?: string };

/**
 * Set media query breakpoint values.
 *
 * The values used here are based on the data from
 * [Material Design docs]{@link https://material.io/design/layout/responsive-layout-grid.html#breakpoints}.
 *
 * We initialise these as a map so we can retain the ordering for certain use cases
 *
 * This map gets reassigned to {@link breakpoint} object for easy consumption eg. breakpoint.medium instead of breakpoint.get('medium')
 */
export const breakpointMap: Map<breakpoint_keys, number> = new Map([
  ['base', 0], // required for column count
  ['xxsmall', 360],
  ['xsmall', 480],
  ['small', 600],
  ['smedium', 720],
  ['medium', 840],
  ['mlarge', 960],
  ['large', 1024],
  ['xlarge', 1280],
  ['xxlarge', 1440],
]);

/**
 * Media query breakpoint values
 *
 * This is generated from the {@link breakpointMap}.
 */
export const breakpoint: breakpointDef_number = [
  ...breakpointMap.entries(),
].reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

/**
 * Set container width {Component: Container}
 */
const containerWidth: string = '1310px';

/**
 * Set container width for narrow content restriction. {Component: Container}
 */
const containerNarrowWidth: string = '800px';

/**
 * Set gutter values for layout columns {Component: Column}
 */
const gutter: breakpointDef_string = {
  base: '0.8rem',
  medium: '1.6rem',
};

/**
 * Set column count values for layout columns {Component: Column}
 */
const columnCount: breakpointDef_number = {
  base: 12,
};

/**
 * A generated list of column count for all breakpoints
 */
const columns: breakpointDef_number = Array.from(
  breakpointMap.entries()
).reduce((obj, [key]) => {
  return {
    ...obj,
    [key]:
      columnCount[key] ||
      obj[Object.keys(obj).pop() as breakpoint_keys] ||
      columnCount[Object.keys(columnCount)[0] as breakpoint_keys],
  };
}, columnCount[Object.keys(columnCount)[0] as breakpoint_keys] as breakpointDef_number);

export const spacing: {
  [key in 'xsmall' | 'small' | 'medium' | 'large']: [string, string];
} = {
  xsmall: ['0.8rem', '1.6rem'],
  small: ['1.6rem', '3.2rem'],
  medium: ['2.4rem', '4rem'],
  large: ['4.8rem', '6.4rem'],
};

export default {
  breakpointMap,
  breakpoint,
  columns,
  containerWidth,
  containerNarrowWidth,
  gutter,
  spacing,
};
