import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const FooterStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      bgColor: theme?.color?.dark_green,
    };

    validateProps('FooterStyled', { ...props });

    return css`
      ${spacer('medium', 'padding-top-bottom')}

      background: ${props.bgColor};
      border-top: 1px solid #fff;
      color: #fff;

      text-align: center;

      .nav {
        margin-top: 1.6rem;

        a {
          text-decoration: none;
          color: inherit;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      @media ${mq.mlarge} {
        .inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .nav {
          margin-top: 0;
          display: flex;
          &__item {
            padding: 0 1.6rem;
            &:not(:last-child) {
              border-right: 1px solid;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    `;
  }}
`;

export default FooterStyled;
