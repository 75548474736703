import styled, { css } from 'styled-components';

import {
  breakpointDef_number,
  breakpointDef_string,
  breakpoint_keys,
} from '../../../theme/layout';

import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const ColumnStyled = styled.div<{
  columns: breakpointDef_number;
}>`
  ${({ columns = { base: 12 }, theme }) => {
    const props: {
      breakpointMap: Map<breakpoint_keys, number>;
      columnCount: breakpointDef_number;
      gutter: breakpointDef_string;
    } = {
      breakpointMap: theme?.layout?.breakpointMap,
      columnCount: theme?.layout?.columns,
      gutter: theme?.layout?.gutter,
    };

    let baseWidth = '100%';

    if (columns?.base && props.columnCount?.base) {
      let width = (100 / props.columnCount.base) * columns.base;
      if (width > 100) width = 100;

      baseWidth = `${width}%`;
    }

    let count = columns[Object.keys(columns)[0] as breakpoint_keys];
    let columnCount = props.columnCount
      ? props.columnCount[Object.keys(props.columnCount)[0] as breakpoint_keys]
      : 12;

    validateProps('ColumnStyled', { ...props });

    return css`
      flex-shrink: 0;
      flex-grow: 0;
      width: ${baseWidth};

      ${columns &&
      props.columnCount &&
      // loop through props.columnCount breakpoints and set flex basis
      // if columns breakpoint value is greater than the number of columns
      // for that breakpoint then set flex basis to 100%
      Object.keys(props.columnCount)
        .filter((x) => x !== 'base')
        .map((key: breakpoint_keys) => {
          if (columns[key] || props.columnCount[key] !== columnCount) {
            if (columns[key]) count = columns[key];
            if (props.columnCount[key] !== columnCount)
              columnCount = props.columnCount[key];

            let width = (100 / props.columnCount[key]) * count;
            if (width > 100) width = 100;

            return `@media ${mq[key]} { width: ${width}%; }`;
          }
        })}

      .gutter {
        height: 100%;
        ${props.gutter &&
        // loop through gutter breakpoints and set css padding value
        Object.keys(props.gutter)
          .map((key: breakpoint_keys) => {
            if (key === 'base') return `padding:0 ${props.gutter[key]};`;
            else return `@media ${mq[key]} {padding:0 ${props.gutter[key]};}`;
          })
          .join('\n')}
      }
    `;
  }}
`;

export default ColumnStyled;
