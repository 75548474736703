import React from 'react';

import MarkupStyled from './markup.styled';

const Markup = (props: { className?: string; html: string }) => {
  if (!props.html) return null;

  return (
    <MarkupStyled
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: props.html,
      }}
    />
  );
};

export default Markup;
