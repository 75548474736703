import React from 'react';
import { ThemeProvider } from 'styled-components';

import Footer from '~/components/footer';
import Header from '~/components/header';
import Meta from '~/components/meta';
import NotificationBanner from '~/components/notificationBanner';
import SchemaOrg from '~/components/schemaOrg';
import { SchemaOrgTypes } from '~/components/schemaOrg/schemaOrg.types';
import { meta_schema } from '~/schema/site.schema';

import theme from '~/theme';
import GlobalStyles from '~/theme/global';
import { webfontConfig } from '~/theme/typography';

const MasterLayout = (props: {
  children: any;
  pageContext: { title: string; metaDescription: string; [key: string]: any };
  uri: string;
}) => {
  if (typeof window !== 'undefined') {
    const loader = require('webfontloader');
    loader.load(webfontConfig);
  }

  const { notificationBanner, title, metaDescription } = props.pageContext;

  const WebSiteSchema: SchemaOrgTypes.Schema_WebSite = {
    '@type': 'WebSite',
    name: meta_schema.site_name,
    url: meta_schema.domain,
  };

  const WebPageSchema: SchemaOrgTypes.Schema_WebPage = {
    '@type': 'WebPage',
    name: title,
    description: metaDescription,
  };

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Meta
          title={title}
          description={metaDescription}
          canonicalPath={props.uri}
        />
        <SchemaOrg schema={WebSiteSchema} />
        <SchemaOrg schema={WebPageSchema} />
        {notificationBanner && <NotificationBanner {...notificationBanner} />}
        <Header />
        <main>{props.children}</main>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default MasterLayout;
