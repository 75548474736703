import React from 'react';

import SpacerStyled from '../component.styled/spacer.styled';

const Spacer = (props: {
  children: any;
  size: 'small' | 'medium' | 'large';
}) => {
  return <SpacerStyled size={props.size}>{props.children}</SpacerStyled>;
};

export default Spacer;
