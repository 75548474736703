import React from 'react';

import ContainerStyled from '../component.styled/container.styled';

const Container = (props: { children: any; narrow?: boolean }) => {
  return (
    <ContainerStyled className="container" narrow={props.narrow}>
      {props.children}
    </ContainerStyled>
  );
};

export default Container;
