import { withPrefix } from 'gatsby';
import styled, { css } from 'styled-components';
import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const HeaderStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      divideColor: '#eee',
      linkColor: theme?.color?.text?.base,
      linkHoverColor: theme?.color?.link?.base,
    };

    validateProps('HeaderStyled', { ...props });

    return css`
      background-color: #fff;
      text-align: center;

      .logo {
        display: inline-block;
        text-decoration: none;
        font-size: 0;
        line-height: 0;
        margin-top: 1.6rem;

        @media ${mq.smedium} {
          margin-top: 3.2rem;
        }

        img {
          width: auto;
          height: 4.8rem;

          @media ${mq.smedium} {
            height: 8.8rem;
          }
        }
      }

      .nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-transform: uppercase;
        border-top: 1px solid ${props.divideColor};

        margin-top: 1.6rem;

        @media ${mq.smedium} {
          margin-top: 2.4rem;
          padding: 1.6rem 0;
        }

        &__item {
          flex-basis: 50%;
          text-align: center;
          border-bottom: 1px solid ${props.divideColor};
          &:nth-child(2n + 1) {
            border-right: 1px solid ${props.divideColor};
          }

          @media ${mq.xxsmall} {
            flex-basis: auto;
            border: none !important;
          }
        }

        a {
          display: block;
          position: relative;
          padding: 0.8rem;
          font-size: 1.4rem;
          line-height: 1.6rem;
          text-decoration: none;
          color: ${props.linkColor};

          @media ${mq.xxsmall} {
            padding: 0.8rem 1rem;
          }

          @media ${mq.xsmall} {
            padding: 0.8rem 1.6rem;
          }

          @media ${mq.smedium} {
            font-size: 2rem;
            padding: 0.8rem 0;
            margin: 0 1.6rem;
          }

          &:hover {
            background-color: ${props.linkHoverColor};
            color: #fff;

            @media ${mq.smedium} {
              background-color: transparent;
              color: ${props.linkColor};
              font-size: 2rem;

              &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: ${props.linkHoverColor};
              }
            }
          }
        }
      }

      .image-strip {
        opacity: 0.6;
        width: 100%;
        height: 4rem;
        background-image: url(${withPrefix('/images/image-strip.png')});
        background-repeat: repeat-x;
        background-position: center center;
        background-size: auto 100%;

        @media ${mq.xsmall} {
          height: 5.4rem;
        }

        @media ${mq.smedium} {
          height: 8rem;
        }
      }
    `;
  }}
`;

export default HeaderStyled;
