import { breakpoint, breakpoint_keys } from './layout';

export const webfontConfig = {
  google: {
    families: ['Lato:300,400,700'],
  },
};

const Lato = 'Lato, sans-serif';

/**
 * Font family definitions
 */
export const fontFamily: {
  default: string;
  heading: string;
  [key: string]: string;
} = {
  default: Lato,
  heading: Lato,
};

/**
 * Config to declare core typography styles.
 * This is used to generate css that can be consumed in components
 * to maintain uniform font styles across all device sizes.
 * Use keys defined in {@link breakpoint} to invoke media query based styles
 */
type typographyDef = {
  [key: string]: { [key in breakpoint_keys]?: { [key: string]: any } };
};

const typography: typographyDef = {
  default: {
    base: {
      'font-size': '1.6rem',
      'line-height': '2.4rem',
      'font-family': fontFamily.default,
    },
  },
  h1: {
    base: {
      'font-size': '3.2rem',
      'line-height': '4rem',
    },
    small: {
      'font-size': '4.8rem',
      'line-height': '5.6rem',
    },
  },
  h2: {
    base: {
      'font-size': '2.4rem',
      'line-height': '3.2rem',
    },
    small: {
      'font-size': '3.2rem',
      'line-height': '4rem',
    },
  },
  h3: {
    base: {
      'font-size': '2rem',
      'line-height': '2.4rem',
    },
    small: {
      'font-size': '2.4rem',
      'line-height': '3.2rem',
    },
  },
  h4: {
    base: {
      'font-size': '1.6rem',
      'line-height': '2.4rem',
    },
  },
  h5: {
    base: {
      'font-size': '1.6rem',
      'line-height': '2.4rem',
    },
  },
  h6: {
    base: {
      'font-size': '1.6rem',
      'line-height': '2.4rem',
    },
  },
};

/**
 * This is generated from {@link typography}
 * Creates an object of css styles assigned to keys
 */
export const typeStyles: { [key: string]: string } = Object.keys(
  typography
).reduce((obj, key) => {
  const styles = (Object.keys(typography[key]) as breakpoint_keys[])
    .map((screenSize) => {
      const screenSizeobj = typography[key][screenSize];

      const props = Object.keys(screenSizeobj)
        .map((key) => `${key}:${screenSizeobj[key]}`)
        .join(';\n');

      if (screenSize === 'base') {
        return props;
      } else {
        return `@media only screen and (min-width: ${breakpoint[screenSize]}px) { ${props} }`;
      }
    })
    .join(';\n');
  return { ...obj, [key]: styles };
}, {});

// /**
//  * Creates css string from {@link typography} object.
//  * Uses {@link breakpoint} variable to generate media query css output.
//  */
// const generateTypeStyles = (typography: typographyDef ) => {
//   Object.keys(typography).map((key) => {
//     const styles = (Object.keys(typography[key]) as breakpoint_keys[])
//       .map((screenSize) => {
//         const screenSizeobj = typography[key][screenSize];

//         const props = Object.keys(screenSizeobj)
//           .map((key) => `${key}:${screenSizeobj[key]}`)
//           .join(';\n');

//         if (screenSize === 'base') {
//           return props;
//         } else
//           return `@media only screen and (min-width: ${breakpoint[screenSize]}px) { ${props} }`;
//       })
//       .join(';\n');

//     typeStyles[key] = styles;
//   });
// };

// generateTypeStyles(typography);

export default {
  fontFamily,
  typeStyles,
};
