import React from 'react';
import Helmet from 'react-helmet';
import { meta_schema } from '~/schema/site.schema';

const Meta = (props: {
  canonicalPath?: string;
  title?: string;
  type?: 'article' | 'profile' | 'website' | 'video';
  description?: string;
}) => {
  const {
    author,
    authorTwitterHandle,
    published_date_time,
    ogDescription,
  }: {
    author?: string;
    authorTwitterHandle?: string;
    published_date_time?: string;
    ogDescription?: string;
  } = {};

  const {
    domain,
    site_name,
    site_title,
    websiteTwitterHandle,
    first_name,
    last_name,
    ogImage,
    ogImageAlt,
  } = meta_schema;

  const locale = 'en_GB';

  const twitterCardType: 'summary' | 'summary_large_image' | 'app' | 'player' =
    'summary';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>{`${props.title ? props.title : ''}${
        props.title && site_title ? ` - ` : ''
      }${site_title}`}</title>
      <meta name="description" content={props.description} />

      {/* <meta name="robots" content="noindex, nofollow" /> */}

      {/* {currentPath !== canonicalPath && (
        <link rel="canonical" href={`${domain}${canonicalPath}`} />
      )} */}

      {/*
        The name of the site
      */}
      <meta property="og:site_name" content={site_name} />
      {/*
      The canonical URL for your page. This should be the undecorated
      URL, without session variables, user identifying parameters, or counters.
      Likes and Shares for this URL will aggregate at this URL. For example,
      mobile domain URLs should point to the desktop version of the URL as the
      canonical URL to aggregate Likes and Shares across different versions of
      the page.
      */}
      <meta property="og:url" content={`${domain}${props.canonicalPath}`} />
      {/*
      The title of your article without any branding such as your site name.
      */}
      <meta property="og:title" content={props.title} />
      {/* 
        A brief description of the content, usually between 2 and 4 sentences. 
        This will displayed below the title of the post on Facebook.
      */}
      <meta
        property="og:description"
        content={ogDescription || props.description}
      />
      {/*
        recommended 1.91:1 (1200:630)
      */}
      <meta property="og:image" content={`${domain}${ogImage}`} />
      {/*
        recommended per https://ogp.me/ guidelines
      */}
      <meta property="og:image:alt" content={ogImageAlt || site_name} />
      {/*
        Use og:image:width and og:image:height Open Graph tags to specify the image dimensions to the crawler
        so that it can render the image immediately without having to asynchronously download and process it.
      */}
      <meta property="og:image:width" content={'1200'} />
      <meta property="og:image:height" content={'630'} />
      {/*
        The type of media of your content. 
        This tag impacts how your content shows up in News Feed. 
        If you don't specify a type,the default is website. 
        Each URL should be a single object, so multiple og:type values are not possible.
      */}
      <meta property="og:type" content={props.type || 'website'} />
      {/*
        The locale these tags are marked up in.
      */}
      <meta property="og:locale" content={locale} />
      {/* conditional og meta */}
      {props.type === 'article' && published_date_time && (
        <>
          <meta
            property="article:published_time"
            content={published_date_time}
          />
          {author && <meta property="article:author" content={author} />}
        </>
      )}
      {props.type === 'profile' && first_name && last_name && (
        <>
          <meta property="profile:first_name" content={first_name} />
          <meta property="profile:last_name" content={last_name} />
        </>
      )}
      {/*
        The card type, which will be one of “summary”, “summary_large_image”, “app”, or “player”.
      */}
      <meta name="twitter:card" content={twitterCardType} />
      {/*
        Twitter handle of the website
      */}
      {websiteTwitterHandle && (
        <meta name="twitter:site" content={websiteTwitterHandle} />
      )}
      {/*
        Twitter handle of the content creator
      */}
      {authorTwitterHandle && (
        <meta name="twitter:creator" content={authorTwitterHandle} />
      )}
    </Helmet>
  );
};

export default Meta;
