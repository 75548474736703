import { css } from 'styled-components';
import { spacing } from '../layout';
import mq from './mq.pattern';

/**
 * Breakpoint aware spacing definitions
 */
export default (
  size: 'small' | 'medium' | 'large',
  property:
    | 'margin-top'
    | 'padding-top-bottom'
    | 'padding-top'
    | 'padding-bottom' = 'margin-top'
) => {
  const prop = property === 'padding-top-bottom' ? 'padding' : property;
  return css`
    ${prop}: ${spacing[size][0]} ${property === 'padding-top-bottom' ? 0 : ''};
    @media ${mq.small} {
      ${prop}: ${spacing[size][1]} ${property === 'padding-top-bottom'
        ? 0
        : ''};
    }
  `;
};
