import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const SpacerStyled = styled.div<{
  size: 'small' | 'medium' | 'large';
}>`
  ${({ size, theme }) => {
    const props = {
      spacingStyles: theme?.layout?.spacing,
    };

    validateProps('SpacerStyled', { ...props });

    return css`
      ${size && spacer(size)}
    `;
  }}
`;

export default SpacerStyled;
