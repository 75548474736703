export const meta_schema: {
  site_title: string;
  domain: string;
  site_name: string;
  websiteTwitterHandle?: string;
  first_name?: string;
  last_name?: string;
  full_name: string;
  ogImage: string;
  ogImageAlt: string;
} = {
  site_title: 'David J Fry - Watercolor, Oil & Acrylic Paintings and Prints',
  domain: 'https://www.davidjfryfineart.co.uk',
  site_name: 'David J Fry - Watercolor, Oil & Acrylic Paintings and Prints',
  //websiteTwitterHandle: null,
  first_name: 'David',
  last_name: 'Fry',
  full_name: 'David J Fry',
  ogImage: '/images/david-j-fry.png',
  ogImageAlt: 'David J Fry - Watercolor, Oil & Acrylic Paintings and Prints',
};

export const content_page_schema = {
  home: {
    id: 'ckkeiyp2ww7vb0a56ga3lb6dc',
    path: '/',
    title: 'Home',
    includeInMenu: true,
  },
  paintings: {
    id: 'ckkfuap7s39j20956pladmlmp',
    path: '/paintings/',
    title: 'Paintings',
    includeInMenu: true,
  },
  about: {
    id: 'ckkodsam87l9a0a03t3bpfc2i',
    path: '/about/',
    title: 'About',
    includeInMenu: true,
  },
  greeting_cards: {
    id: 'ckkofvtr48co10a03jb6d7nmw',
    path: '/greeting-cards/',
    title: 'Greeting Cards',
    includeInMenu: false,
  },
  contact: {
    id: 'ckkofphcw89z10a51e9haj4km',
    path: '/contact/',
    title: 'Contact',
    includeInMenu: true,
  },
  delivery: {
    id: 'ckkofmtog894q0a51wbezs3yd',
    path: '/delivery-and-postage/',
    title: 'Delivery & Postage',
    includeInMenu: false,
  },
  prints: {
    id: 'ckkoetqm0mnwy0b97nsfckeuv',
    path: '/limited-edition-prints/',
    title: 'Prints',
    includeInMenu: false,
  },
};
