import styled, { css } from 'styled-components';

import { breakpointDef_string, breakpoint_keys } from '~/theme/layout';

import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const ContainerStyled = styled.div<{ narrow: boolean }>`
  ${({ narrow, theme }) => {
    const props: {
      containerWidth: string;
      containerNarrowWidth: string;
      gutter: breakpointDef_string;
    } = {
      containerWidth: theme?.layout?.containerWidth,
      containerNarrowWidth: theme?.layout?.containerNarrowWidth,
      gutter: theme?.layout?.gutter,
    };

    validateProps('ContainerStyled', { ...props });

    return css`
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: ${narrow ? props.containerNarrowWidth : props.containerWidth};

      padding: 0 ${props.gutter?.base};

      ${props.gutter && (Object.keys(props.gutter) as breakpoint_keys[]).filter((x) => x !== 'base')
        .map((key) => `@media ${mq[key]} { padding: 0 ${props.gutter[key]}; }`)
        .join('\n')
      }
    `;
  }}
`;

export default ContainerStyled;
