import styled, { css } from 'styled-components';

import { breakpointDef_number, breakpointDef_string, breakpoint_keys } from '~/theme/layout';
import { mq } from '~/theme/patterns';

// import validateProps from '~/utils/validateProps';

const FlexGridStyled = styled.div<{
  columns: breakpointDef_number;
  gutter: breakpointDef_string;
}>`
  ${({ columns = { base: 1 }, gutter = { base: '1.6rem' } }) => {
    // const props = {};

    // validateProps('FlexGridStyled', { ...props });

    return css`
      margin: 0 -${gutter ? gutter[Object.keys(gutter)[0] as breakpoint_keys] : '0'};

      ${gutter &&
      (Object.keys(gutter) as breakpoint_keys[])
        .filter((x) => x !== 'base')
        .map((key) => `@media ${mq[key]} { margin: 0 -${gutter[key]}; }`)
        .join('\n')}

      .wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
      }

      .item {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: ${columns?.base ? 100 / columns.base : 100}%;
        &:nth-child(-n + ${columns?.base ? columns.base : 1}) .item__padding {
          padding-top: 0;
        }

        ${columns &&
        (Object.keys(columns) as breakpoint_keys[])
          .filter((x) => x !== 'base')
          .map(
            (key) =>
              `@media ${mq[key]} { 
                  flex-basis: ${100 / columns[key]}%; 
                  
                  &:nth-child(-n + ${columns[key]}) .item__padding {
                    padding-top: 0;
                  }
                }`
          )
          .join('\n')}
      }

      .item__padding {
        height: 100%;
        padding: 0 ${gutter ? gutter[Object.keys(gutter)[0] as breakpoint_keys] : '0'};
        padding-top: ${gutter
          ? doubleGutter(gutter[Object.keys(gutter)[0] as breakpoint_keys])
          : '0'};
        ${gutter &&
        (Object.keys(gutter) as breakpoint_keys[])
          .filter((x) => x !== 'base')
          .map(
            (key) =>
              `@media ${mq[key]} { padding: 0 ${
                gutter[key]
              }; padding-top: ${doubleGutter(gutter[key])} }`
          )
          .join('\n')};
      }
    `;
  }}
`;

export default FlexGridStyled;

const doubleGutter = (gutter: number | string) => {
  if (typeof gutter === 'number') return gutter * 2;

  const units =
    gutter.indexOf('px') > 0
      ? 'px'
      : gutter.indexOf('rem') > 0
      ? 'rem'
      : gutter.indexOf('em') > 0
      ? 'em'
      : null;
  if (!units) return gutter;

  return `${parseFloat(gutter.replace(units, '')) * 2}${units}`;
};
