import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import FlexGridStyled from '../component.styled/flexGrid.styled';

import { breakpointDef_number, breakpointDef_string } from '~/theme/layout';

const FlexGrid = ({
  renderAsUnorderedList = false,
  ...props
}: {
  children: any;
  columns: breakpointDef_number;
  gutter: breakpointDef_string;
  renderAsUnorderedList?: boolean;
}) => {
  if (!props.children || props.children.length < 1) return null;

  const FlexWrapper = renderAsUnorderedList ? 'ul' : 'div';
  const FlexItem = renderAsUnorderedList ? 'li' : 'div';

  return (
    <FlexGridStyled
      className="flex-grid"
      columns={props.columns}
      gutter={props.gutter}
    >
      <FlexWrapper className="wrapper">
        {props.children.map((child: any) => (
          <FlexItem className="item" key={uuidv4()}>
            <div className="item__padding">{child}</div>
          </FlexItem>
        ))}
      </FlexWrapper>
    </FlexGridStyled>
  );
};

export default FlexGrid;
