import React from 'react';
import { Container } from '../layout';
import Markup from '../markup';

import NotificationBannerStyled from './notificationBanner.styled';

const NotificationBanner = (props: { content?: string; title: string }) => {
  return (
    <NotificationBannerStyled>
      <Container narrow>
        <div className="title">{props.title}</div>
        {props.content && <Markup className="content" html={props.content} />}
      </Container>
    </NotificationBannerStyled>
  );
};

export default NotificationBanner;
