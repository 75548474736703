import { createGlobalStyle } from 'styled-components';
import { typeStyles } from './typography';
import color from './color';
import { withPrefix } from 'gatsby';

// const loadFonts = () => {
//   if (typeof window !== 'undefined') {
//     const WebFont = require('webfontloader');
//     WebFont.load({
//       google: {
//         families: googleFonts,
//       },
//     });
//   }
// };

// loadFonts();

const GlobalStyle = createGlobalStyle`
    html, body {
      /* Sets 1rem equal to 10px */
      font-size: 62.5%;
    }

    body{
      min-height: 100vh;
      scroll-behavior: smooth;
      text-rendering: auto;
      color: ${color?.text?.base};
      ${typeStyles?.default};
      background: url(${withPrefix('/images/background-texture.png')}) repeat;
      background-attachment: fixed;
    }    
    
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    
    * {
      margin: 0;
      padding: 0;
    }
    
    img {
      max-width:100%;
      width:100%;
      height:auto;
    }

    #root {
      overflow-x:hidden;
    }

    main {
      min-height: 64vh;
      overflow:hidden;
    }
  `;
export default GlobalStyle;
