import styled, { css } from 'styled-components';
import { breakpointDef_string, breakpoint_keys } from '~/theme/layout';
import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const RowStyled = styled.div<{
  reverse: boolean;
}>`
  ${({ reverse, theme }) => {
    const props: { gutter: breakpointDef_string } = {
      gutter: theme?.layout?.gutter,
    };

    validateProps('RowStyled', { ...props });

    return css`
      display: flex;
      flex-wrap: wrap;
      ${reverse && `flex-direction:row-reverse;`}

      ${props.gutter &&
      (Object.keys(props.gutter) as breakpoint_keys[])
        .map((key) => {
          if (key === 'base')
            return `margin-left: -${props.gutter[key]}; margin-right: -${props.gutter[key]};`;
          else
            return `@media ${mq[key]} { margin-left: -${props.gutter[key]}; margin-right: -${props.gutter[key]}; }`;
        })
        .join('\n')}
    `;
  }}
`;

export default RowStyled;
