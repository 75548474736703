import { Link } from 'gatsby';
import React from 'react';
import { content_page_schema } from '~/schema/site.schema';
import { Container } from '../layout';

import FooterStyled from './footer.styled';

const nav = Object.keys(content_page_schema).reduce(
  (
    arr: { path: string; id: string; title: string }[],
    key: keyof typeof content_page_schema
  ) => {
    return [...arr, content_page_schema[key]];
  },
  []
);

const Footer = () => {
  var date = new Date();
  var year = date.getFullYear();

  return (
    <FooterStyled>
      <Container>
        <div className="inner">
          <div className="copy">All images copyright David J Fry {year}</div>
          <nav className="nav">
            {nav.map((item) => (
              <div className="nav__item" key={item.title}>
                <Link to={item.path}>{item.title}</Link>
              </div>
            ))}
          </nav>
        </div>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
