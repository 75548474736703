import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { MainLayout } from './src/layouts/index';

// Wraps every page in a component
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageContext = props.pageContext as {
    title: string;
    metaDescription: string;
    [key: string]: any;
  };

  return (
    <MainLayout pageContext={pageContext} uri={props.uri as string}>
      {element}
    </MainLayout>
  );
};
