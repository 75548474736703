import styled, { css } from 'styled-components';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const MarkupStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      h1Styles: theme?.typeStyles?.h1,
      h2Styles: theme?.typeStyles?.h2,
      h3Styles: theme?.typeStyles?.h3,
      h4Styles: theme?.typeStyles?.h4,
      h5Styles: theme?.typeStyles?.h5,
      h6Styles: theme?.typeStyles?.h6,
      //pStyles: theme?.typeStyles?.p,
      linkColor: theme?.color?.link?.base,
      linkHoverColor: theme?.color?.link?.hover,
    };

    validateProps('MarkupStyled', { ...props });

    return css`
      > * {
        margin: 2.4rem 0 0 0;
      }

      > *:first-child {
        margin-top: 0;
      }

      h1 {
        ${props.h1Styles}
      }
      h2 {
        ${props.h2Styles}
      }
      h3 {
        ${props.h3Styles}
      }
      h4 {
        ${props.h4Styles}
      }
      h5 {
        ${props.h5Styles}
      }
      h6 {
        ${props.h6Styles}
      }

      ul,
      ol {
        padding: 0;
        list-style-position: inside;

        ul,
        ol {
          padding-left: 3.2rem;
        }
      }

      li {
        margin-top: 0.8rem;
      }

      a {
        color: ${props.linkColor};

        &:hover {
          color: ${props.linkHoverColor};
        }
      }

      .table-wrap {
        width: 100%;
        overflow: auto;
      }

      table {
        width: 100% !important;
        border: none;
        border-right: 1px solid #e6e7e8;
        border-left: 1px solid #e6e7e8;
        border-collapse: collapse;
        border-spacing: 0;
        text-align: left;

        > caption {
          ${props.h3Styles}
          padding-bottom: 1.6rem;
          text-align: left;
        }
      }

      thead,
      tfoot {
        background: #e6e7e8;
      }

      tbody {
        width: 100% !important;
      }

      tr {
        height: auto !important;
        border-bottom: 1px solid #e6e7e8;
      }

      td {
        padding: 0.8rem 1.6rem;
      }
    `;
  }}
`;

export default MarkupStyled;
